import React from "react";

import Container from "../components/Container/container";
import "../components/Returns/returns.scss";

const Shipping = () => (
	<div className="returns">
		<Container style={{ maxWidth: "800px" }}>
			<h2>Shipping</h2>
			<h3>Domestic Shipping Methods & Delivery</h3>
			<p>
				Tearribles.com offers shipping service with multiple carriers
				within the United States. Shipping services available are
				Economy (5-8 business days), and Faster (3-5 business days).
				Expedited shipping options may from time to time become
				available to you within the checkout process at prices
				calculated at checkout. Please note that most carriers only
				deliver Monday through Friday (excluding holidays). We are not
				responsible for shipping delays due to inclement weather and we
				do not issue refunds for shipping costs if a delivery date is
				missed. We do ship to P.O. Boxes or military APO/DPO/FPO
				addresses and there may be an additional charge reflected at
				checkout for such deliveries.
			</p>
			<h3>International Shipping</h3>
			<p>
				Tearribles.com offers international shipping to UK, EU, NZ, and
				Canada at this time, and rates are calculated at checkout. Usual
				transit time is 1-3 weeks for all orders that ship
				internationally. For UK and EU Tearribles.com will collect
				applicable import fees and taxes on the customers' behalf and
				submit their payment to the applicable governmental tax agency.
				International orders do not ship with insurance, and customer
				has the ability to purchase such insurance at a small cost at
				checkout.
			</p>
			<h3>Shipping Notification</h3>
			<p>
				When your order has shipped, you will receive a shipping
				notification email from us containing your tracking information.
				With this number, you will be able to monitor the progress of
				your package as it is in transit to you.
			</p>
			<h3>Incorrect Shipping Addresses and Refused Delivery</h3>
			<p>
				We will make every attempt to validate the shipping address
				provided at checkout to insure it is recognized as a valid
				address with our carriers. If we are unable to validate the
				address and we are unable to contact the customer to provide an
				updated address, the order will be cancelled and refunded.
			</p>
			<p>
				In the event the customer provides an incorrect or partial
				shipping address when placing their order and it's returned by
				the carrier or if delivery is refused and returned to sender,
				Tearribles will cancel the order and provide a full refund of
				the original purchase price, minus the shipping and handling
				charges.
			</p>
			<p>
				Tearribles.com will not be held responsible If the customer
				provides the wrong or incomplete shipping address and we are
				unable to recover the package.
			</p>
			<h3>Free Shipping</h3>
			<p>
				Occasionally we may offer free shipping promotion on orders that
				meet a current threshold, which is currently $50.00 for all US
				orders, $75 for all UK and Canadian orders, and $100 for
				Scandinavian and certain EU countries. For Australian promotions
				please visit out au.tearribles.com website.
			</p>
			<h3>Missing or Stolen Shipments</h3>
			<p>
				If you didn't receive your order but the shipping carrier is
				reporting that it has been delivered, please contact us as soon
				as possible. We will file a claim with the carrier who will
				perform a detailed investigation and potentially involve local
				law enforcement to recover your package. Upon completion of this
				investigation we may be able to replace or recover your order as
				a result of the investigation. Please allow up to 21 days for
				the completion of the investigation.
			</p>
			<h3>Lost Shipments</h3>
			<p>
				If the carrier loses your shipment in transit, and you purchased
				shipping insurance, you will be able to file a claim and receive
				a replacement fo the lost items up to $100, and $200. Upon
				completion of the claim we will replace your order as covered by
				the terms of the coverage. Please allow up to 14 days for the
				claims to be processed and replacement to be dispatched. If you
				did not insure your package and your package is lost in transit,
				please contact us within 14 days of the expected delivery date
				so we can open a missing mail search with the carrier.
			</p>
		</Container>
	</div>
);

export default Shipping;
